<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click.prevent="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
        >New Record</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Add a stopword</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="term"
                  required
                  label="Stopword"
                  ref="f_term"
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="dialog = false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  ADD_STOPWORD,
  PULL_STOPWORDS,
  REFRESH
} from "@/core/services/store/stopwords.module";
import Swal from "sweetalert2";

export default {
  data: () => ({
    valid: true,
    dialog: false,
    term: ""
  }),
  methods: {
    refresh: function() {
      this.term = "";
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch(ADD_STOPWORD, { term: this.$refs.f_term.value })
          .then(() => {
            this.dialog = false;
            this.$store.dispatch(REFRESH);
            this.$store.dispatch(PULL_STOPWORDS);
            Swal.fire(
              "Added!",
              "At midnight be set on search engine. For immediately click on `force reload` on right top.",
              "success"
            );
          });
      }
    }
  },
  validations: {
    form: {
      name: { required }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.stopwords.errors,
      message: state => state.stopwords.message,
      hasMessage: state => state.stopwords.hasMessage,
      term: state => state.stopwords.term
    })
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
  }
};
</script>
