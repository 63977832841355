<template>
  <v-btn @click="reloadElastic" :loading="this.loadingElastic" color="warning">
    Force Reload
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import { RELOAD_ELASTIC } from "@/core/services/store/elastic.module";

export default {
  computed: {
    ...mapState({
      loadingElastic: state => state.elastic.loading
    })
  },
  methods: {
    reloadElastic() {
      Swal.fire({
        title: "Are you sure?",
        text: `In reload may shutdown search on page in a few seconds.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reload it!"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(RELOAD_ELASTIC).then(() => {
            Swal.fire("Reloaded!", "", "success");
          });
        }
      });
    }
  }
};
</script>
